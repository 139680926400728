<div class="document-details h-full flex flex-column ml-2 mr-2 relative"
     *ngIf="(document$ |async); else noDocument">
  <div class="flex flex-row align-items-stretch flex-1 h-full">
    <div class="document flex-1 relative" [ngStyle]="{'overflow-y': htmlMode ? 'scroll' : 'auto'}">
      <ngx-extended-pdf-viewer *ngIf="!htmlMode" [src]="(workItemBlob$ | async) || ''"
                               [textLayer]="true"
                               [handTool]="false"
                               [sidebarVisible]="false"
                               [showPrintButton]="false"
                               [showOpenFileButton]="false"
                               height="100%"
                               zoom="page-width"
      ></ngx-extended-pdf-viewer>
      <div *ngIf="htmlMode" [innerHTML]="(workItemHtml$ | async)"></div>
    </div>
    <div class="fields flex-auto flex-grow-0 w-30rem ml-1 relative overflow-hidden flex flex-column h-full" pFocusTrap>
      <div class="flex-1 overflow-x-hidden overflow-y-auto">
        <div class="my-3">
          <p-toggleButton
            class="p-fluid"
            *ngIf="showButtonCommunication"
            [(ngModel)]="checked"
            onLabel="Document"
            offLabel="Communication"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            (onChange)="loadHtmlContent()">
          </p-toggleButton>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</div>

<ng-template #noDocument>
  <div class="no-document text-center m-4">
    <i class="pi pi-check-square m-3" style="font-size: 5rem;"></i>
    <h2>Terminé</h2>
    <p>
      Il ne semble plus y avoir de document à traiter.
    </p>
    <p>
      <button type="button" pButton
              class="p-button-text p-button-plain"
              (click)="reloadWorkList()"
              label="Rafraichir"
      ></button>
    </p>
  </div>
</ng-template>
