<div class="document-fields-form flex flex-column justify-content-start pb-6 h-full">
  <ng-container *ngIf="loadingFormModel$ | async; else withFormModel">
    <div class="flex-0 text-center">
      <p>
        Chargement...
      </p>
    </div>
  </ng-container>

  <ng-template #withFormModel>
    <form class="flex-0" #fieldForm="ngForm" novalidate
          (ngSubmit)="onSubmit(fieldForm)"
          ctrlEnterKeyDown (keyDown)="onSubmit(fieldForm)"
    >

      <div class="displayed-field" *ngIf="displayedFieldModel; else noDisplayedField">

        <div class="title p-widget flex flex-row align-items-center justify-content-start">
          <span class="flex-1 font-bold mb-2 mt-2 ">
          {{ displayedFieldModel.fieldModel.field.label }}
          </span>
          <span class="flex-0">
             <nit-field-problem-type-select
               class="inline-block"
               *ngIf="(displayedFieldEditable$ | async) || true"
               [fieldType]="displayedFieldModel.fieldModel.field.fieldType || undefined"
               [filterdProblemTypeOptions]="displayedFieldModel.problemTypeWhitelist"
               [includeUntypedProblemOption]="includeUntypedProblemOptions"
               ngModel (ngModelChange)="onSetFieldProblemClick($event)"
               [name]="'problem-'+displayedFieldModel.fieldModel.field.code+'-'+displayedFieldModel.fieldModel.indexerValue$.getValue()?.id"
             ></nit-field-problem-type-select>
          </span>

          <p-dialog header="Commentaire" [modal]="true" [(visible)]="popupVisible" [style]="{ width: '25rem' }">
            <span class="p-text-secondary block mb-5">A propos du problème</span>
            <textarea pInputTextarea id="username"  [(ngModel)]="problemComment" [ngModelOptions]="{standalone: true}" class="w-full mb-4"></textarea>
            <div class="flex justify-content-end gap-2">
              <p-button label="Save" (onClick)="onSubmitPopupProblemClick()" />
            </div>
          </p-dialog>
        </div>

        <idx-document-field
          [ngClass]="displayedFieldModel.fieldModel.field.code"
          [formFieldModel]="displayedFieldModel"
          [readOnly]="(displayedFieldEditable$ | async) === false"
          #idxDocumentField
        >
        </idx-document-field>

        <div class="actions mt-2" *ngIf="(displayedFieldEditable$ | async); else notEditable">
          <ng-container *ngIf="(displayedFieldLastToIndex$ | async); else hasMoreFields">
            <input type="submit" style="display: none;" [disabled]="fieldForm.invalid" #submitInput>
            <button type="button" pButton
                    class="w-full"
                    label="Envoyer"
                    [disabled]="!fieldForm.valid || (submittingValues$ | async)"
                    (click)="submitInput.click()"
            ></button>
          </ng-container>

          <ng-template #hasMoreFields>
            <button type="button" pButton
                    class="w-full  p-button-link"
                    label="Passer au champ suivant"
                    (focus)="switchToNextField()"
                    (click)="switchToNextField()">
            </button>
          </ng-template>
        </div>
      </div>

      <ng-template #noDisplayedField>
        <input type="submit" style="display: none;" [disabled]="fieldForm.invalid" #submitInput>
        <button type="button" pButton
                class="w-full"
                label="Envoyer"
                [disabled]="!fieldForm.valid || (submittingValues$ | async)"
                (click)="submitInput.click()"
        ></button>
      </ng-template>

      <ng-template #notEditable>
        <div>
          <p>
            Rien à indexer ici
          </p>
        </div>
      </ng-template>
    </form>


    <div class="separator flex-grow-1"></div>

    <div class="indexed-fields flex-0 text-sm">
      <div *ngFor="let fieldModel of otherIndexedFields$ | async" class="indexed-field">

        <label>{{fieldModel.fieldModel.field.label}}</label>

        <div class="indexed-field w-full flex align-items-center">

          <ng-container *ngIf="fieldModel.fieldModel.indexerValue$.getValue(); else noIndexerValue">
            <ng-container
              *ngIf="fieldModel.fieldModel.indexerValue$.getValue()?.valueStatus === WsFieldIdentificationValueStats.Displayed; else indexerSubmitted">

              <div class="field-value flex-1">
                <ng-container
                  *ngIf="fieldModel.pendingSubmitLocalChanges?.valueStatus === WsFieldIdentificationValueStats.Problem; else indexerNonProblematic">
                  <p-messages severity="warn"
                              class="w-full" styleClass="w-full"
                  >
                    <ng-template pTemplate="">
                      <div class="px-3 py-2 w-full">
                        <div>
                          <nit-field-problem-type [problemType]="fieldModel.pendingSubmitLocalChanges?.fieldProblemType"
                                                  [fieldType]="fieldModel.fieldModel.field.fieldType || undefined"
                                                  [showProblemDescription]="true"
                          ></nit-field-problem-type>
                        </div>
                        <ng-container *ngIf="fieldModel.pendingSubmitLocalChanges?.fieldProblemDetails">
                          <div>
                            <span class="text-xs">{{fieldModel.pendingSubmitLocalChanges?.fieldProblemDetails}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                  </p-messages>
                </ng-container>
                <ng-template #indexerNonProblematic>
                  <idx-document-field [formFieldModel]="fieldModel"
                                      [showInfoMessage]="false"
                                      [readOnly]="true"
                  ></idx-document-field>
                </ng-template>
              </div>
              <button type="button" pButton
                      class="p-button-text"
                      (click)="onRestoreFieldClick(fieldModel)"
                      icon="pi pi-undo"
              ></button>

            </ng-container>
            <ng-template #indexerSubmitted>

              <div class="field-value flex-1">
                <ng-container
                  *ngIf="fieldModel.fieldModel.indexerValue$.getValue()?.valueStatus === WsFieldIdentificationValueStats.Problem; else indexerSubmittedNonProblematic">
                  <p-messages severity="warn"
                              class="w-full" styleClass="w-full"
                  >
                    <ng-template pTemplate="">
                      <div class="px-3 py-2 w-full">
                        <div>
                          <nit-field-problem-type
                            [problemType]="fieldModel.fieldModel.indexerValue$.getValue()?.fieldProblemType"
                            [fieldType]="fieldModel.fieldModel.field.fieldType || undefined"
                            [showProblemDescription]="true"
                          ></nit-field-problem-type>
                        </div>
                        <ng-container *ngIf="fieldModel.fieldModel.indexerValue$.getValue()?.fieldProblemDetails">
                          <div>
                            <span
                              class="text-xs">{{fieldModel.fieldModel.indexerValue$.getValue()?.fieldProblemDetails}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                  </p-messages>
                </ng-container>
                <ng-template #indexerSubmittedNonProblematic>
                  <idx-document-field [formFieldModel]="fieldModel"
                                      [showInfoMessage]="false"
                                      [readOnly]="true"
                  ></idx-document-field>
                </ng-template>
              </div>

            </ng-template>
          </ng-container>
          <ng-template #noIndexerValue>

            <div class="request-value flex-1">
              <ng-container
                *ngIf="fieldModel.fieldModel.fieldRequest.requestStatus === WsFieldIdentificationRequestStatus.Problem; else notProblem">
                <p-messages severity="warn"
                            class="w-full" styleClass="w-full"
                >
                  <ng-template pTemplate="">
                    <div class="px-3 py-2 w-full">
                      <div>
                        <nit-field-problem-type
                          [problemType]="fieldModel.fieldModel.fieldRequest.fieldProblemType"
                          [fieldType]="fieldModel.fieldModel.field.fieldType || undefined"
                          [showProblemDescription]="true"
                        ></nit-field-problem-type>
                      </div>
                    </div>
                  </ng-template>
                </p-messages>
              </ng-container>
              <ng-template #notProblem>
                <div *ngIf="fieldModel.fieldModel.fieldRequest.identifiedValue">
                  <nit-field-request-value [requestRef]="{id: fieldModel.fieldModel.fieldRequest.id!}"
                  ></nit-field-request-value>
                </div>
              </ng-template>
            </div>

          </ng-template>
        </div>

      </div>
    </div>


  </ng-template>
</div>
