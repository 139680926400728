import {WsNitroApiError} from "@fiscalteam/nitro-domain-client";
import {Message, MessageService} from "primeng/api";

export class AppShellModalHelpers {
  static OPTIMISTIC_LOCK_ERROR_CODE = 'OPTIMISTIC_LOCK';
  static OPTIMISTIC_LOCK_MODAL_KEY = 'optimistic-lock-errors';

  /**
   * Show an optimistic lock error modal for the provided error.
   * The error is expected to be a valid WsNitroApiError with error code 'OPTIMISTIC_LOCK' (see {#OPTIMISTIC_LOCK_ERROR_CODE})
   *
   * The primeng message service is provided in the app shell and used to display the modal toast.
   *
   * @param messageService
   * @param error
   * @param onReload
   * @param onOverwrite
   */
  static showOptimisticLockModal(messageService: MessageService, error: WsNitroApiError, onReload: () => void, onOverwrite?: () => void): void {
    let message = this.createOptimisticLockMessage(error, onReload, onOverwrite);
    messageService.add(message);
  }

  static callOptimisticLockReloadCallback(message: Message) {
    if (message && message.data && message.data.onReload) {
      if (typeof message.data.onReload === "function") {
        message.data.onReload();
      }
    }
  }

  static callOptimisticLockOverwriteCallback(message: Message) {
    if (message && message.data && message.data.onOverwrite) {
      if (typeof message.data.onOverwrite === "function") {
        message.data.onOverwrite();
      }
    }
  }


  private static createOptimisticLockMessage(error: WsNitroApiError, onReload: () => void, onOverwrite?: () => void): Message {
    return {
      key: AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY,
      sticky: true,
      closable: true,
      summary: `${error.message || 'Modifications conflictuelles'}`,
      detail: '', // not used in template
      severity: 'warn',
      data: {
        // Pass the callback as message data
        onReload: onReload,
        onOverwrite: onOverwrite,
      }
    }
  }

}
