<div class="document-field" *ngIf="fieldModel">
  <nit-indexing-field-input class="w-full"
                            [indexedFieldModel]="fieldModel.fieldModel"
                            [readOnly]="(busy$ | async) || readOnly"
                            [disabled]="disabled"
                            [indexingState]="(indexingState$ | async) || undefined"
                            #fieldInputComponent
  >

    <ng-template [nitFieldInputTemplate]="'thirdPartyReferenceNoItemResult'"
                 let-value="inputValue">
      <div class="w-full cursor-pointer"
           (click)="onNoValueFoundCLick(value, WsFieldProblemType.ThirdpartyDoesNotExists); $event.stopImmediatePropagation(); $event.preventDefault()"
      >
        <nit-field-problem-type [fieldType]="fieldModel.fieldModel.field.fieldType || undefined"
                                [problemType]="WsFieldProblemType.ThirdpartyDoesNotExists"
                                [showIcon]="true"
                                [showProblemTypeLabel]="true"
                                [showProblemDescription]="true"
        >
        </nit-field-problem-type>
        <div>
          Cliquez ici pour remonter l'erreur
        </div>
      </div>
    </ng-template>


    <ng-template [nitFieldInputTemplate]="'ibanNoItemResult'"
                 let-value="inputValue">
      <div class="w-full cursor-pointer"
           (click)="onNoValueFoundCLick(value, WsFieldProblemType.IbanDoesNotExist); $event.stopImmediatePropagation(); $event.preventDefault()"
      >
        <nit-field-problem-type [fieldType]="fieldModel.fieldModel.field.fieldType || undefined"
                                [problemType]="WsFieldProblemType.IbanDoesNotExist"
                                [showIcon]="true"
                                [showProblemTypeLabel]="true"
                                [showProblemDescription]="true"
        >
        </nit-field-problem-type>
        <div>
          Cliquez ici pour remonter l'erreur
        </div>
      </div>
    </ng-template>
  </nit-indexing-field-input>

  <ng-container *ngIf="(fieldTypedValue$ | async) != null; else noValue">
    <div *ngIf="showInfoMessage && fieldModel.indexerValuesSyncSubscription != null; else noSync">
      <ng-container *ngIf="fieldModel.fieldModel.indexerValueSyncing$ | async; else notSyncing">
        <p-message severity="success"
                   [text]="'Envoi...'"
                   class="w-full" styleClass="w-full"
        ></p-message>
      </ng-container>
      <ng-template #notSyncing>
        <ng-container *ngIf="!(fieldModel.fieldModel.hasError$() | async)">
          <p-message severity="success"
                     [text]="(readOnly ? 'Enregistré' : 'OK')"
                     class="w-full" styleClass="w-full"
          ></p-message>
        </ng-container>
      </ng-template>

      <p-message severity="error" *ngFor="let e of (submitErrors)"
                 [text]="e"
                 class="w-full" styleClass="w-full"
      ></p-message>

    </div>
    <ng-template #noSync>

    </ng-template>
  </ng-container>

  <ng-template #noValue>
  </ng-template>

</div>
